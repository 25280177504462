import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import 'dropzone/dist/min/dropzone.min.css'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import 'react-dropzone-component/styles/filepicker.css'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import Switch from "react-switch"
import swal from 'sweetalert2'
import '../../assets/css/vec-dropzone.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Dialog from '../../commons/dialog/Dialog.js'
import Security from '../../commons/security/Security.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import backendStrings from '../../lang/backendStrings.js'
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import Loading from '../ui/Loading.js'
import PersonasAbmAdjuntos from './PersonasAbmAdjuntos.js'

class PersonasAbm extends Component {
    constructor(props) {
        super(props);

        this.ajaxHandler = new AjaxHandler();

        moment.locale('es');

        this.state = {
            redirectTo: null,
            props: this.props,
            persona: null,
            formData: {
                id: 0,
                nombre: '',
                apellido: '',
                moviles: null,
                documentoTipo: null,
                documentoNumero: 0,
                nacionalidad: null,
                sexo: '',
                sync: '',
                fechaNacimiento: null,
                esActivo: true,
                telefonoCodArea: 0,
                telefonoNumero: 0,
                celularCodArea: 0,
                celularNumero: 0,
                email: '',
                observaciones: '',
                calle: '',
                numero: 0,
                piso: '',
                departamento: '',
                localidad: null,
                provincia: null,
                pais: null,
                legajoNumero: '',
                fechaIngreso: null,
                fechaBaja: null,
                base: null,
                categoria: null,
                contrato: '',
                ypfRuta: '',
                comentariosLaborales: '',
                esUsuario: false,
                isInternal: false,
                usuario: null,
                foto: '',
                adjuntos: [],
                centroCostos: null,
                basesAsignadas: null,
                ccAsignados: [],
                companiaOrigen: '',
                llaves: null,
                email_SSO: '',
                compania_origen_id: null
            },
            tiposDocumento: [],
            tiposDocumentoLoading: false,
            paises: [],
            paisesLoading: false,
            provincias: [],
            provinciasLoading: false,
            localidades: [],
            localidadesLoading: false,
            sexos: [{ value: 'F', label: this.props.intl.formatMessage({ id: 'personasAbm.general_data.select_sexo.femenino', defaultMessage: 'Femenino' })},{ value: 'M', label: this.props.intl.formatMessage({ id: 'personasAbm.general_data.select_sexo.masculino', defaultMessage: 'Masculino' })}],
            syncs: [{ value: false, label: this.props.intl.formatMessage({ id: 'personasAbm.general_data.select_sync.no', defaultMessage: 'No' })},{ value: true, label: this.props.intl.formatMessage({ id: 'personasAbm.general_data.select_sync.si', defaultMessage: 'Sí' })}],
            estados: [{ value: false, label: this.props.intl.formatMessage({ id: 'personasAbm.general_data.select_state.inactivo', defaultMessage: 'Inactivo' })},{ value: true, label: this.props.intl.formatMessage({ id: 'personasAbm.general_data.select_state.activo', defaultMessage: 'Activo' })}],
            categorias: [],
            categoriasLoading: false,
            basesDisponibles: [],
            basesDisponiblesLoading: false,
            disableAvlFields: false,
            perfiles: [],
            perfilesLoading: false,
            moviles: [],
            gerenciadores: [],
            hasPermisoMovil: false,
            hasPermisoGerenciador: false,
            hasPerfilGestor: false,
            hasPermisoEscribano: false,
            hasPermisoBases: false,
            hasPermisoTrabajarTodasBases: false,
            hasPermisoCC: false,
            hasPermisoVariosVehiculos: false,
            errors: [],
            loading: false,
            basesAsignadas: [],
            ccAsignados: [],
            ypfRutaView: false,
            centroCostos: [],
            centroCostosLoading: false,
            cecoEnabled: false,
            cecoMandatory: false,
            cecoSource: null,
            baseMandatory: false,
            companiasOrigen: [],
            companiaOrigenEnabled: false,
            companiasLoading: false,
            llavesOptions: [],
            llavesOptionsLoading: false,
            subRegion: null,
            regiones: [],
            subRegiones: [],
            regionesLoading: false,
            subRegionesLoading: false,
            basesDisponiblesDualList: [],
            ccDisponiblesDualList: [],
            direccionForm: null,
            basesFaltantes: [],
            ccFaltantes: [],
            ssoHabilitado: ConfigBusiness.get('personas.usa_sso.habilitado') === 'true' ? true : false,
            validationErrors: {
                email_SSO: ''
            },
        };

        this.handleEstadoChange = this.handleEstadoChange.bind(this);
        this.handlePaisChange = this.handlePaisChange.bind(this);
        this.handleProvinciaChange = this.handleProvinciaChange.bind(this);
        this.handleLocalidadChange = this.handleLocalidadChange.bind(this);
        this.handleEsUsuarioChange = this.handleEsUsuarioChange.bind(this);
        this.handleEsInternoChange = this.handleEsInternoChange.bind(this);
        this.handleEnableTwoFAChange = this.handleEnableTwoFAChange.bind(this);
        this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
        this.handleInputFormChange = this.handleInputFormChange.bind(this);
        this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);
        this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
        this.handleFormChangeUsuarioSelectObject = this.handleFormChangeUsuarioSelectObject.bind(this);
        this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsuarioFormChange = this.handleUsuarioFormChange.bind(this);
        this.handlePerfilChange = this.handlePerfilChange.bind(this);
        this.handleGerenciadorChange = this.handleGerenciadorChange.bind(this);
        this.handleBasesChange = this.handleBasesChange.bind(this);
        this.handleCCChange = this.handleCCChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleChangeDireccion = this.handleChangeDireccion.bind(this);
        this.handleSubRegionChange = this.handleSubRegionChange.bind(this);
        this.validateUsuarioSSO = this.validateUsuarioSSO.bind(this);

        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'formData.nombre': (value) => Validator.notEmpty(value),
                'formData.apellido': (value) => Validator.notEmpty(value),
                'formData.documentoNumero': (value) => value ? Validator.numeroDocumento(value) : false,
                'formData.telefonoCodArea': (value) => value ? Validator.intNumber(value): { valid:true, message:""},
                'formData.telefonoNumero': (value) => value ? Validator.intNumber(value): { valid:true, message:""},
                'formData.celularCodArea': (value) => value ? Validator.intNumber(value): { valid:true, message:""},
                'formData.celularNumero': (value) => value ? Validator.intNumber(value): { valid:true, message:""},
                'formData.documentoTipo': (value) => Validator.notEmpty(value),
                'formData.legajoNumero': (value) => Validator.notEmpty(value),
                'formData.fechaNacimiento': (value) => Validator.date(value),
                'formData.email': (value) => Validator.conditionalEmail(this.state.formData.esUsuario, value),
                'formData.email_SSO': (value) => Validator.conditionalEmail(false, value),
                'formData.fechaIngreso': (value) => Validator.dateBeforeToday(value),
                'formData.fechaBaja': (value) => Validator.dateBeforeToday(value),
                'formData.usuario.usuario': (value) => Validator.conditionalNotEmpty(this.state.formData.esUsuario, value),
                'formData.usuario.perfil': (value) => Validator.conditionalNotEmpty(this.state.formData.esUsuario, value),
                'formData.centroCostos': (value) => Validator.conditionalNotEmpty(this.state.cecoMandatory, value),
                'formData.base': (value) => Validator.conditionalNotEmpty(this.state.baseMandatory, value),
                'formData.direccionValid': (value) => Validator.notEmpty(value),                
            }
        });

        this.initPhotoUpload();
    }

    componentDidMount() {
        if((Security.hasPermission('PERSONAS_CREAR') && this.state.props.action === 'ADD') ||
            (Security.hasPermission('PERSONAS_MODIFICAR') && this.state.props.action === 'EDIT') ||
            (Security.hasPermission('PERSONAS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
            this.ajaxHandler.subscribe(this);
            this.initForm();
        } else {
            this.setState({
                redirectTo: '/error'
            });
        }

        let persona = JSON.parse(localStorage.getItem('persona'));
        this.setState({
            persona: persona,
        });
    }

    componentWillUnmount() {
        this.ajaxHandler.unsubscribe();
    }

    initForm() {
        let component = this;

        let companiaOrigenEnabled = ConfigBusiness.get('personas.companiaOrigen.habilitado') === 'true' ? true : false;
        this.setState({
            companiaOrigenEnabled: companiaOrigenEnabled
        });

        if(this.state.props.action === 'VIEW') this.loadFormData();

        if(this.state.props.action === 'ADD' || this.state.props.action === 'EDIT') {
            this.setState({
                tiposDocumentoLoading: true,
                paisesLoading: true,
                categoriasLoading: true,
                basesDisponiblesLoading: true,
                perfilesLoading: true,
                centroCostosLoading: true,
                loading: this.state.props.action === 'EDIT',
                companiasLoading: true,
                llavesOptionsLoading: true,
                regionesLoading: false,
                movilesOptionsLoading: true
            });

            Promise.all([
                this.ajaxHandler.getJson('/tipos-documento/select'),
                this.ajaxHandler.getJson('/paises/select'),
                this.ajaxHandler.getJson('/persona-categorias/select'),
                this.ajaxHandler.getJson('/bases/select'),
                this.ajaxHandler.getJson('/perfiles'),
                this.ajaxHandler.getJson('/moviles/selectActiveAndInactive'),
                this.ajaxHandler.getJson('/gerenciadores/select'),
                this.ajaxHandler.getJson('/centros-costos/selectobservaciones'),
                ConfigBusiness.get('personas.ypfRuta.habilitado'),
                ConfigBusiness.get('personas.centroCostos.habilitado'),
                ConfigBusiness.get('personas.centroCostos.obligatorio'),
                ConfigBusiness.get('personas.centroCostos.origen'),
                ConfigBusiness.get('personas.base.obligatorio'),
                this.ajaxHandler.getJson('/companias-origen/select'),
                this.ajaxHandler.getJson('/llaves'),
                this.ajaxHandler.getJson('/regiones/select'),
                this.ajaxHandler.getJson('/centros-costos/select'),//se traen los centros de costo para perfil con permiso USUARIO_TRABAJAR_CC
                this.ajaxHandler.getJson('/moviles/usuarios'),
                ConfigBusiness.get('pointer.disableFields') === 'true'
            ]).then((data) => {


                component.setState({
                    tiposDocumentoLoading: false,
                    paisesLoading: false,
                    categoriasLoading: false,
                    basesDisponiblesLoading: false,
                    perfilesLoading: false,
                    centroCostosLoading: false,
                    companiasLoading: false,
                    llavesOptionsLoading: false,
                    regionesLoading: false,
                    movilesOptionsLoading: false
                });
                let tiposDocumento = data[0] ? data[0] : [];
                let paises = data[1] ? data[1] : [];
                let categorias = data[2] ? data[2] : [];
                let basesDisponibles = data[3] ? data[3] : [];
                let basesDisponiblesDualList = data[3] ? data[3] : [];
                let perfiles = data[4] ? data[4] : [];
                let moviles = data[5] ? data[5] : [];
                let gerenciadores = data[6] ? data[6] : [];
                let centroCostos = data[7] ? data[7] : [];
                let ypfRutaView = data[8] === 'true' ? true : false;
                let cecoEnabled = data[9] === 'true' ? true : false;
                let cecoMandatory = data[10] === 'true' ? true : false;
                let cecoSource = data[11];
                let baseMandatory = data[12] === 'true' ? true : false;
                let companiasOrigen = data[13] ? data[13] : [];
                let llavesOptions = data[14] ? data[14].map(e => ({value: e.id, label: e.codigo})): [];
                let regiones = data[15] ? data[15] : [];

                let ccDisponibles = data[16] ? data[16] : [];
                let ccDisponiblesDualList = data[16] ? data[16] : [];

                let movilesOptions = data[17] ? data[17].map(e => ({value: e.value, label: e.label})): [];

                let disableAvlFields = false
                if(ConfigBusiness.get('avl_service.service') === 'pointer') {
                  disableAvlFields = ConfigBusiness.get('pointer.disableFields') === 'true' ? true: false
                }
                if(ConfigBusiness.get('avl_service.service') === 'geotab') {
                  disableAvlFields = ConfigBusiness.get('geotab.disableFields') === 'true' ? true: false
                }
                component.setState({
                    tiposDocumento: tiposDocumento,
                    paises: paises,
                    categorias: categorias,
                    basesDisponibles: basesDisponibles,
                    basesDisponiblesDualList: basesDisponiblesDualList,
                    ccDisponibles: ccDisponibles,
                    ccDisponiblesDualList: ccDisponiblesDualList,
                    perfiles: perfiles,
                    moviles : moviles,
                    gerenciadores : gerenciadores,
                    centroCostos: centroCostos,
                    ypfRutaView: ypfRutaView,
                    cecoEnabled: cecoEnabled,
                    cecoMandatory: cecoMandatory,
                    cecoSource: cecoSource,
                    disableAvlFields: disableAvlFields,
                    baseMandatory: baseMandatory,
                    companiasOrigen: companiasOrigen,
                    llavesOptions: llavesOptions,
                    regiones: regiones,
                    movilesOptions: movilesOptions
                }, () => {
                    component.state.props.action === 'EDIT' ? this.loadFormData() : this.initDualListbox();
                });

            }).catch(function(error) {
                component.ajaxHandler.handleError(error);
            });
        }

        window.scrollTo(0, 0);
        $('#nombre').focus();
    }

    initDualListbox() {
        $(this.refs.duallistbox).bootstrapDualListbox({
            moveOnSelect: false,
            moveAllLabel: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.title_mover_todos', defaultMessage: 'Mover todos' }),
            removeAllLabel: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.title_remover_todos', defaultMessage: 'Remover todos' }),
            moveSelectedLabel: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.title_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
            removeSelectedLabel: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.title_remover_seleccionados', defaultMessage: 'Remover seleccionados' }),
            infoText: false,
            filterPlaceHolder: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.label_filtro', defaultMessage: 'Filtro' }),
            nonSelectedListLabel: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.label_bases_disponibles', defaultMessage: 'Bases Disponibles *:' }),
            selectedListLabel: this.props.intl.formatMessage({ id: 'personasAbm.base_administrator.label_bases_asignadas', defaultMessage: 'Bases Asignadas:' })
        });

        $(this.refs.duallistbox).change((e) => {
            this.setState({
                basesAsignadas: $(this.refs.duallistbox).val().map(e => parseInt(e, 10))
            }, ()=>{ $(this.refs.duallistbox).bootstrapDualListbox('refresh', true);});
        });

        //Se inicializa componente para centro de costos
        $(this.refs.duallistboxCC).bootstrapDualListbox({
          moveOnSelect: false,
          moveAllLabel: "Mover todos",
          removeAllLabel: "Remover todos",
          moveSelectedLabel: "Mover seleccionados",
          removeSelectedLabel: "Remover seleccionados",
          infoText: false,
          filterPlaceHolder: "Filtro",
          nonSelectedListLabel: "Centros de Costos Disponibles *:",
          selectedListLabel: "Centros de Costos Asignados:"
      });

      $(this.refs.duallistboxCC).on("change",(e) => {
          this.setState({
              ccAsignados: $(this.refs.duallistboxCC).val().map(e => parseInt(e, 10))
          }, ()=>{ $(this.refs.duallistboxCC).bootstrapDualListbox('refresh', true);});
      });
    }

    loadFormData() {
        let component = this;
        this.setState({ loading: true });
        Promise.all([
            // Obtiene los datos de la persona por ID
            this.ajaxHandler.getJson('/personas/' + this.state.props.match.params.id),

            // Verifica si la ruta YPF está habilitada
            ConfigBusiness.get('personas.ypfRuta.habilitado'),

            // Obtiene todas las llaves
            this.ajaxHandler.getJson('/llaves'),

            // Obtiene los usuarios móviles asociados a la persona por ID
            this.ajaxHandler.getJson('/personas/usuariosmoviles/' + this.state.props.match.params.id),

            // Obtiene todos los usuarios de móviles
            this.ajaxHandler.getJson('/moviles/usuarios')
        ]).then(data => {
            // data[0]: Datos de la persona
            // data[1]: Estado de habilitación de la ruta YPF
            // data[2]: Lista de llaves
            // data[3]: Usuarios móviles asociados a la persona
            // data[4]: Lista de usuarios de móviles
            if (data[0].esUsuario && data[0].usuario && data[0].usuario.perfil) {
                data[0].usuario.perfil.permisos.includes('USUARIO_POSEER_MOVIL') ? this.setState({ hasPermisoMovil: true }) : this.setState({ hasPermisoMovil: false });
                data[0].usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_GERENCIADOR') ? (
                    this.setState({
                        hasPermisoGerenciador : true
                    }, () => {
                        this.ajaxHandler.getJson('/gerenciadores/tipo/1/select')
                            .then((data) => {
                                let gerenciadores = data ? data : null;
                                this.setState({
                                    gerenciadores: gerenciadores
                                });
                            });
                    })
                ) : this.setState({ hasPermisoGerenciador : false });

                data[0].usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_GESTOR') ? (
                    this.setState({
                        hasPerfilGestor : true
                    }, () => {
                        this.ajaxHandler.getJson('/gerenciadores/tipo/2/select')
                            .then((data) => {
                                let gerenciadores = data ? data : null;
                                this.setState({
                                    gerenciadores: gerenciadores
                                });
                            });
                    })
                ) : this.setState({ hasPerfilGestor : false });

                data[0].usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_ESCRIBANO') ? (
                    this.setState({
                        hasPermisoEscribano : true
                    }, () => {
                        this.ajaxHandler.getJson('/gerenciadores/tipo/3/select')
                            .then((data) => {
                                let gerenciadores = data ? data : null;
                                this.setState({
                                    gerenciadores: gerenciadores
                                });
                            });
                    })
                ) : this.setState({ hasPermisoEscribano : false });
                data[0].usuario.perfil.permisos.includes('USUARIO_TRABAJAR_BASES') ? this.setState({ hasPermisoBases : true }) : this.setState({ hasPermisoBases : false });
                data[0].usuario.perfil.permisos.includes('USUARIO_TRABAJAR_CON_TODAS_LAS_BASES') ? this.setState({ hasPermisoTrabajarTodasBases : true }) : this.setState({ hasPermisoTrabajarTodasBases : false });
                data[0].usuario.perfil.permisos.includes('USUARIO_TRABAJAR_CC') ? this.setState({ hasPermisoCC : true }) : this.setState({ hasPermisoCC : false });
                data[0].usuario.perfil.permisos.includes('USUARIO_TRABAJAR_VEHICULOS') ? this.setState({ hasPermisoVariosVehiculos : true }) : this.setState({ hasPermisoVariosVehiculos : false });
            }

            let formData = Object.assign(this.state.formData, data[0]);
            formData.isInternal = data[0].is_internal;
            formData.email_SSO = data[0].usuario ? data[0].usuario.email_SSO : ''; 
            
            let direccionForm = {
                calle: formData.calle,
                numero: formData.numero,
                localidad: formData.localidad,
                provincia: formData.provincia,
                pais: formData.pais,
                latitud: formData.latitud,
                longitud: formData.longitud
            };

            if(data[0]) component.setState({
                formData: formData,
                direccionForm: direccionForm
            }, () => {
                if(component.props.action !== 'ADD') {
                    let basesAsignadas = [];
                    basesAsignadas = this.state.formData.usuario && this.state.formData.usuario.bases ? ( component.props.action === 'EDIT' ? this.state.formData.usuario.bases.map(e => e.id) : this.state.formData.usuario.bases.map(e => e.descripcion) ) : [];

                    let ccAsignados = [];
                    ccAsignados = this.state.formData.usuario && this.state.formData.usuario.centroCostos ? ( component.props.action === 'EDIT' ? this.state.formData.usuario.centroCostos.map(e => e.id) : this.state.formData.usuario.centroCostos.map(e => e.nombre) ) : [];

                    component.setState({
                        basesAsignadas: basesAsignadas,
                        ccAsignados: ccAsignados
                    }, () => {
                        this.initDualListbox();
                    });

                    if(component.state.formData.foto) {
                        let photoUrl = '/personas/foto/' +component.state.formData.foto;
                        component.ajaxHandler.fetch(photoUrl, {
                            method: 'GET',
                            headers: {
                                'Authorization-Token': localStorage.getItem("token")
                            }
                        }).then(response => {
                            if (response.status === 200) {
                                return response.blob();
                            }
                        }).then(imgBlob => {
                            let imgUrl = URL.createObjectURL(imgBlob);
                            // Create the mock file:
                            var mockFile = { name: "Filename", size: 12345 };
                            this.dropzone.emit("addedfile", mockFile);
                            this.dropzone.files.push(mockFile);
                            this.dropzone.emit("thumbnail", mockFile, imgUrl);
                            this.dropzone.emit("complete", mockFile);
                        }).catch(() => {
                        });
                    }
                }
            });
            let ypfRutaView = data[1] === 'true' ? true : false;

            component.setState({
                ypfRutaView: ypfRutaView
            })


            let moviles = data[3] ? data[3].map(e => ({value: e.value, label: e.label})): [];
            component.setState({

              formData: {
                ...formData,
                moviles: moviles
              }
            })

            let movilesOptions = data[4] ? data[4].map(e => ({value: e.value, label: e.label})): [];
            component.setState({
              movilesOptions: movilesOptions
            })


        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    initPhotoUpload() {
        let component = this;

        this.photoUploadConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif'],
            showFiletypeIcon: this.props.action === 'ADD' ? true : false,
            postUrl: Config.get('apiUrlBase') + '/personas/foto'
        };

        this.photoUploadDjsConfig = {
            addRemoveLinks: false,
            acceptedFiles: "image/jpeg,image/png,image/gif",
            thumbnailMethod: 'crop',
            dictDefaultMessage: component.state.props.action !== 'VIEW' ? component.props.intl.formatMessage({ id: 'personasAbm.general_data.add_photo.haga_click_aqui_o', defaultMessage: 'Haga click aquí o ' })+"<br>"+component.props.intl.formatMessage({ id: 'personasAbm.general_data.add_photo.arrastre_su_foto_a_este_area.', defaultMessage: ' arrastre su foto a este área.' })+"<br>"+component.props.intl.formatMessage({ id: 'personasAbm.general_data.add_photo.tamano_maximo_de', defaultMessage: 'Tamaño máximo de' })+"<br>"+component.props.intl.formatMessage({ id: 'personasAbm.general_data.add_photo.archivo_20mb', defaultMessage: 'archivo 20MB.' }) : '',
            uploadMultiple: false,
            maxFiles: 1,
            maxFilesize: 20,
            headers: {
                "Authorization-Token": localStorage.getItem("token")
            },
            init: function () {
                let dropzone = this;
                this.on('addedfile', function(file) {
                    $(this.element).find('.dz-details').hide();
                });
                this.on('error', (file, errormessage, response) => {
                  dropzone.removeFile(file);
                  if(errormessage.includes('File is too big')){
                    Dialog.alert(component.props.intl, {
                      title: component.props.intl.formatMessage({ id: 'personasAbm.general_data.add_photo.error_el_archivo_es_demasiado_grande', defaultMessage: 'No se pudo subir el archivo. El mismo supera el tamaño máximo de 20 MB' })
                    });
                  }
                });
                if(component.state.props.action === 'ADD' || component.state.props.action === 'EDIT') {
                    this.on('addedfile', function(file) {
                        if (this.files.length > 1) {
                            this.removeFile(this.files[0]);
                        }
                        file.previewElement.addEventListener("click", function() {
                            dropzone.hiddenFileInput.click();
                        });
                        $(this.element).find('.dz-image > img').css('cursor', 'pointer');
                    });
                } else {
                    dropzone.disable();
                }
            }
        };

        this.photoUploadHandlers = {
            init: dz => {
                this.dropzone = dz;
            },
            success: this.handleFotoSuccess.bind(this)
        };
    }

    handleFotoSuccess(file, response) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.foto = response.foto;
        this.setState({
            formData: formDataCopy
        });
    }

    handleFormChangeSelectString(name, object) {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        if(name==="llaves"){
          formDataCopy['llaves'] = object === null ? '' : object;
          this.setState({
            formData: formDataCopy
          });
        } else if(name==="movilesUsuarios") {
          formDataCopy["moviles"] = object === null ? '' : object;
          this.setState({
            formData: formDataCopy
          });
        } else if(name==="companiaOrigen") {
          formDataCopy["companiaOrigen"] = object === null ? '' : object.label;
          formDataCopy["compania_origen_id"] = object === null ? '' : object.value;
          this.setState({
            formData: formDataCopy
          });
        } else {
          formDataCopy[name] = object === null ? '' : object.value;
          this.setState({
              formData: formDataCopy
          });
        }
    }

    handleFormChangeSelectObject(name, object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = object ? { id: object.value } : null;
        this.setState({
            formData: formDataCopy
        });
    }

    handleRegionChange(object) {
      return new Promise((resolve, reject) => {
          this.state.basesFaltantes.map(function(base) {
            $("#dualListBases option[value='"+base+"']").remove();
          })
          if(object) {
              let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
              formDataCopy.region = { id: object.value, label: object.label };
              this.setState({
                  formData: formDataCopy,
              }, () => {
                  this.setState({ regionesLoading: true });
                  this.ajaxHandler.getJson('/bases/select/' + object.value)
                      .then((data) => {
                          this.setState({
                            basesDisponiblesDualList: data
                          }, () => resolve());
                      }).finally(() => {

                      let basesFaltantes = this.state.basesAsignadas.slice();
                      this.state.basesDisponiblesDualList.map(function(baseDualList){
                        let indice = basesFaltantes.findIndex(element => element == baseDualList.value);
                        if (indice != -1){
                          basesFaltantes.splice(indice,1);
                        }
                      });

                      this.state.basesDisponibles.map(function(base) {
                        let indice = basesFaltantes.findIndex(element => element == base.value);
                        if (indice != -1){
                          $("#dualListBases").append('<option className="pl-1" key='+base.value+' value='+base.value+' selected>'+base.label+'</option>');
                        }
                      })

                      $(this.refs.duallistbox).bootstrapDualListbox('refresh');
                      this.setState({
                                    regionesLoading: false,
                                    basesFaltantes: basesFaltantes
                                    });
                    });
              });
              Promise.all([
                this.ajaxHandler.getJson('/subregiones/' + object.value + '/select')
              ]).then((data) => {
                let subregiones = data[0];
                this.setState({
                    subRegiones: subregiones,
                });
              }).catch(function(error) {
                console.log(error);
              });
          } else {
              let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
              formDataCopy.region = null;
              this.setState({
                  formData: formDataCopy,
              }, () => {
                this.setState({ regionesLoading: true });
                this.setState({ subRegion: null });
                this.ajaxHandler.getJson('/bases/select')
                    .then((data) => {
                        this.setState({
                          basesDisponiblesDualList: data
                        }, () => resolve());
                    }).finally(() => {
                      $(this.refs.duallistbox).bootstrapDualListbox('refresh');
                      this.setState({
                                    regionesLoading: false,
                                    basesFaltantes: []
                                    });
                  });
            });
          }
      });
    }

    handleSubRegionChange(object){
        return new Promise((resolve, reject) => {
            this.state.basesFaltantes.map(function(base) {
                $("#dualListBases option[value='"+base+"']").remove();
            })
            if(object) {
                let subRegion = { id: object.id, nombre: object.nombre };
                this.setState({
                  subRegion: subRegion,
                });
                this.setState({ subRegionesLoading: true });
                this.ajaxHandler.getJson('/bases/select/subregion/' + object.id)
                .then((data) => {
                    this.setState({
                        basesDisponiblesDualList: data
                    }, () => resolve());
                }).finally(() => {

                    let basesFaltantes = this.state.basesAsignadas.slice();
                    this.state.basesDisponiblesDualList.map(function(baseDualList){
                        let indice = basesFaltantes.findIndex(element => element == baseDualList.value);
                        if (indice != -1){
                            basesFaltantes.splice(indice,1);
                        }
                    });

                    this.state.basesDisponibles.map(function(base) {
                        let indice = basesFaltantes.findIndex(element => element == base.value);
                        if (indice != -1){
                          $("#dualListBases").append('<option className="pl-1" key='+base.value+' value='+base.value+' selected>'+base.label+'</option>');
                        }
                    })

                    $(this.refs.duallistbox).bootstrapDualListbox('refresh');
                    this.setState({
                        subRegionesLoading: false,
                        basesFaltantes: basesFaltantes
                    });
                });



            } else {
                this.setState({
                  subRegion: null,
                });
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                this.setState({
                    formData: formDataCopy,
                }, () => {
                    this.setState({ regionesLoading: true });
                    this.ajaxHandler.getJson('/bases/select/' + formDataCopy.region.id)
                    .then((data) => {
                        this.setState({
                            basesDisponiblesDualList: data
                        }, () => resolve());
                    }).finally(() => {

                        let basesFaltantes = this.state.basesAsignadas.slice();
                        this.state.basesDisponiblesDualList.map(function(baseDualList){
                            let indice = basesFaltantes.findIndex(element => element == baseDualList.value);
                            if (indice != -1){
                                basesFaltantes.splice(indice,1);
                            }
                        });

                        this.state.basesDisponibles.map(function(base) {
                            let indice = basesFaltantes.findIndex(element => element == base.value);
                            if (indice != -1){
                              $("#dualListBases").append('<option className="pl-1" key='+base.value+' value='+base.value+' selected>'+base.label+'</option>');
                            }
                        })

                        $(this.refs.duallistbox).bootstrapDualListbox('refresh');
                        this.setState({
                            regionesLoading: false,
                            basesFaltantes: basesFaltantes
                        });
                    });
                });

            }
        });
    }

    handleBaseChange(object) {
        return new Promise((resolve, reject) => {
            if(object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.base = object ? { id: object.value, label: object.label } : null;
                if(this.state.cecoSource === 'base') formDataCopy.centroCostos = null;
                this.setState({
                    formData: formDataCopy
                }, () => {
                    if(this.state.cecoSource === 'base') {
                        this.ajaxHandler.getJson('/bases/' + object.value)
                            .then((data) => {
                                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                                formDataCopy.centroCostos = data.centroCostos ? data.centroCostos : null;
                                this.setState({
                                    formData: formDataCopy
                                }, () => resolve());
                            }).finally(() => {
                                this.setState({ provinciasLoading: false });
                            });
                    }
                });
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.base = null;
                if(this.state.cecoSource === 'base') formDataCopy.centroCostos = null;
                this.setState({
                    formData: formDataCopy
                }, () => resolve());
            }
        });
    }

    handlePaisChange(object) {
        return new Promise((resolve, reject) => {
            if(object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.pais = { id: object.value, label: object.label };
                formDataCopy.provincia = null;
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy,
                    provincias: [],
                    localidades: []
                }, () => {
                    this.setState({ provinciasLoading: true });
                    this.ajaxHandler.getJson('/provincias/' + object.value + '/select')
                        .then((data) => {
                            this.setState({
                                provincias: data
                            }, () => resolve());
                        }).finally(() => {
                            this.setState({ provinciasLoading: false });
                        });
                });
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.pais = null;
                formDataCopy.provincia = null;
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy,
                    provincias: [],
                    localidades: []
                }, () => resolve());
            }
        });
    }

    handleProvinciaChange(object) {
        return new Promise((resolve, reject) => {
            if(object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.provincia = { id: object.value, label: object.label };
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy,
                    localidades: []
                }, () => {
                    this.setState({ localidadesLoading: true });
                    this.ajaxHandler.getJson('/localidades/' + object.value + '/select')
                        .then((data) => {
                            this.setState({
                                localidades: data
                            }, () => resolve());
                        }).finally(() => {
                            this.setState({ localidadesLoading: false });
                        });
                });
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.provincia = null;
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy,
                    localidades: []
                }, () => resolve());
            }
        });
    }


  	handleChangeDireccion(keyDireccion, valueDireccion, direccionValid){
  		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[keyDireccion] = valueDireccion;
      formDataCopy['direccionValid'] = direccionValid;
  		this.setState({
  			formData: formDataCopy
  		});

  	}


    handleLocalidadChange(object) {
        return new Promise((resolve, reject) => {
            if(object) {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.localidad = { id: object.value, label: object.label };
                this.setState({
                    formData: formDataCopy
                }, () => resolve());
            } else {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy.localidad = null;
                this.setState({
                    formData: formDataCopy
                }, () => resolve());
            }
        });
    }
//TODO: si cambia de estado hay que verificar que no tenga centro de costos
    handleEstadoChange(esActivo) {
        //Verifica si es responsable de alguna base
        if (!esActivo){
            this.verificarSiTieneBasesComoJefe().then(() => {
                this.verificarSiTieneBasesResponsables();
            });
        } else {
            this.setEstadoState(esActivo);
        }
    }

    setEstadoState(esActivo) {
        let formDataCopy = Object.assign({},this.state.formData);
        formDataCopy['esActivo'] = esActivo;
        this.setState({
            formData: formDataCopy
        });
    }

    verificarSiTieneBasesResponsables() {
        if (this.bases === undefined) {
            this.ajaxHandler.getJson('/bases/select-filtered-by-responable/' + this.state.props.match.params.id)
                .then(bases => {
                    this.bases = bases;
                    this.mensajeBases();
                }
                ).catch((error) => {
                    this.setEstadoState(true);
                });
        } else {
            this.mensajeBases();
        }
    }

    mensajeBases(){
        if (this.bases.length !== 0) {
            this.esResponsableDeBases = true;
            let message = "<p>"+this.props.intl.formatMessage({ id: 'personasAbm.modal_base.information_sin_responsables_las_siguientes_bases', defaultMessage: 'Desea dejar sin responsables las siguientes bases?' })+"</p>";
            for (let i = 0; i < this.bases.length; i++) {
                message += "<p>" + this.bases[i].label + "</p>";
            }
            swal.fire({
                title: this.props.intl.formatMessage({ id: 'personasAbm.modal_base.confirm_este_usuario_es_responsable_de_bases', defaultMessage: 'Este usuario es responsable de bases' }),
                html: message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.props.intl.formatMessage({ id: 'personasAbm.modal_base.confirm_aceptar', defaultMessage: 'Aceptar' }),
                cancelButtonText: this.props.intl.formatMessage({ id: 'personasAbm.modal_base.confirm_cancelar', defaultMessage: 'Cancelar' })
            }).then((result) => {
                if (result.value) {
                    this.setEstadoState(false);
                } else {
                    //canceló y vuleve a activado
                    this.setEstadoState(true);
                }
            });
        } else {
            this.setEstadoState(false);
        }
    }

    sacarResponsableDeBase(){
        return this.ajaxHandler.fetch('/bases/removeAsResponsable',
            {
                method: 'POST',
                body: JSON.stringify(
                    {personaId: this.state.formData.id}),
            }).catch((error) => {
                this.setEstadoState(true);
            });
    }

    verificarSiTieneBasesComoJefe() {
        return new Promise((resolve, reject) => {
            if (this.basesJefe === undefined) {
                this.ajaxHandler.getJson('/bases/select-filtered-by-jefe/' + this.state.props.match.params.id)
                    .then(bases => {
                        this.basesJefe = bases;
                        this.mensajeJefes(resolve, reject);
                    }
                    ).catch((error) => {
                        this.setEstadoState(true);
                    });
            } else {
                this.mensajeJefes(resolve, reject);
            }
        });
    }

    mensajeJefes(ok, cancel){
        if (this.basesJefe.length !== 0) {
            this.esJefeDeBases = true;
            let message = "<p>"+this.props.intl.formatMessage({ id: 'personasAbm.modal_jefes.information_desea_dejar_sin_jefe_siguientes_bases', defaultMessage: 'Desea dejar sin Jefe a las siguientes bases?' })+"</p>";
            for (let i = 0; i < this.basesJefe.length; i++) {
                message += "<p>" + this.basesJefe[i].label + "</p>";
            }
            swal.fire({
                title: this.props.intl.formatMessage({ id: 'personasAbm.modal_jefes.confirm_este_usuario_es_jefe_de_bases', defaultMessage: 'Este usuario es Jefe de bases' }),
                html: message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.props.intl.formatMessage({ id: 'personasAbm.modal_jefes.confirm_este_usuario_es_jefe_de_bases.aceptar', defaultMessage: 'Aceptar' }),
                cancelButtonText: this.props.intl.formatMessage({ id: 'personasAbm.modal_jefes.confirm_este_usuario_es_jefe_de_bases.cancelar', defaultMessage: 'Cancelar' })
            }).then((result) => {
                if (result.value) {
                    this.setEstadoState(false);
                    if (ok) ok();
                } else {
                    //canceló y vuleve a activado
                    this.setEstadoState(true);
                    if (cancel) cancel();
                }
            });
        } else {
            this.setEstadoState(false);
            if (ok) ok();
        }
    }

    sacarJefeDeBase(){
        return this.ajaxHandler.fetch('/bases/removeAsJefe',
            {
                method: 'POST',
                body: JSON.stringify(
                    {personaId: this.state.formData.id}),
            }).catch((error) => {
                this.setEstadoState(true);
            });
    }

    handleEsUsuarioChange(esUsuario) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy['esUsuario'] = esUsuario;
        formDataCopy['usuario'] = esUsuario ? ({
            id: 0,
            usuario: '',
            clave: '',
            perfil: null,
            notificacionesActivas: false,
            movil: null,
            gerenciador: null,
            bases:[],
            centroCostos:[]
        }) : null;
        this.setState({
            formData: formDataCopy
        });
    }

    handleEsInternoChange(isInternal) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy['isInternal'] = isInternal;
        this.setState({
            formData: formDataCopy
        });
    }


    handleEnableTwoFAChange(enable_twofa) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario['enable_twofa'] = enable_twofa;
        this.setState({
            formData: formDataCopy
        });
    }

    handleNotificacionesActivasChange(notificacionesActivas) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
        this.setState({
            formData: formDataCopy
        });
    }

    handleInputFormChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = value;
        this.setState({
            formData: formDataCopy
        }, () => {
            if (name === 'email_SSO') {
                this.validateUsuarioSSO();
            }
        });
    }

    handleBasesChange(event) {
        const target = event.target;
        const value = target.value;

        this.setState({
            basesAsignadas: value
        });
    }

    handleCCChange(event) {
      const target = event.target;
      const value = target.value;

      this.setState({
          ccAsignados: value
      });
  }

    handleUsuarioFormChange(event){
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        //TODO: CHEQUEAR QUE EL USUARIO SEA UNICO LO MISMO CON EL NRO. DE LEGAJO
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario[name] = value;
        this.setState({
            formData: formDataCopy
        });
    }

    handlePerfilChange(name, perfil) {
        return new Promise((resolve, reject) => {
            const value = perfil;

            let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy.usuario[name] = value;
            formDataCopy.usuario["movil"] = null;
            formDataCopy.usuario["gerenciador"] = null;
            formDataCopy.usuario["gestor"] = null;
            formDataCopy.usuario["escribano"] = null;
            formDataCopy.basesAsignadas = [];
            formDataCopy.ccAsignados = [];
            $(this.refs.duallistbox).bootstrapDualListbox('refresh',true);
            $(this.refs.duallistboxCC).bootstrapDualListbox('refresh',true);
            this.setState({
                formData: formDataCopy,
                basesAsignadas: [],
                hasPermisoMovil: false,
                hasPermisoGerenciador: false,
                hasPermisoBases: false,
                hasPermisoTrabajarTodasBases: false,
                hasPermisoCC: false,
                hasPermisoVariosVehiculos: false,
                ccAsignados: []
            }, () => {
                if(perfil){
                    perfil.permisos.includes('USUARIO_POSEER_MOVIL') ? this.setState({ hasPermisoMovil : true }) : this.setState({ hasPermisoMovil : false });
                    perfil.permisos.includes('USUARIO_ACTUAR_COMO_GERENCIADOR') ? (
                        this.setState({
                            hasPermisoGerenciador : true
                        }, () => {
                            this.ajaxHandler.getJson('/gerenciadores/tipo/1/select')
                                .then((data) => {
                                    let gerenciadores = data ? data : null;
                                    this.setState({
                                        gerenciadores: gerenciadores
                                    }, () => resolve());
                                });
                        })
                    ) : this.setState({ hasPermisoGerenciador : false });

                    perfil.permisos.includes('USUARIO_ACTUAR_COMO_GESTOR') ? (
                        this.setState({
                            hasPerfilGestor : true
                        }, () => {
                            this.ajaxHandler.getJson('/gerenciadores/tipo/2/select')
                                .then((data) => {
                                    let gerenciadores = data ? data : null;
                                    this.setState({
                                        gerenciadores: gerenciadores
                                    }, () => resolve());
                                });
                        })
                    ) : this.setState({ hasPerfilGestor : false });

                    perfil.permisos.includes('USUARIO_ACTUAR_COMO_ESCRIBANO') ? (
                        this.setState({
                            hasPermisoEscribano : true
                        }, () => {
                            this.ajaxHandler.getJson('/gerenciadores/tipo/3/select')
                                .then((data) => {
                                    let gerenciadores = data ? data : null;
                                    this.setState({
                                        gerenciadores: gerenciadores
                                    }, () => resolve());
                                });
                        })
                    ) : this.setState({ hasPermisoEscribano : false });
                    perfil.permisos.includes('USUARIO_TRABAJAR_BASES') ? this.setState({ hasPermisoBases : true }) : this.setState({ hasPermisoBases : false });
                    perfil.permisos.includes('USUARIO_TRABAJAR_CON_TODAS_LAS_BASES') ? this.setState({ hasPermisoTrabajarTodasBases : true }) : this.setState({ hasPermisoTrabajarTodasBases : false });
                    perfil.permisos.includes('USUARIO_TRABAJAR_CC') ? this.setState({ hasPermisoCC : true }) : this.setState({ hasPermisoCC : false });
                    perfil.permisos.includes('USUARIO_TRABAJAR_VEHICULOS') ? this.setState({ hasPermisoVariosVehiculos : true }) : this.setState({ hasPermisoVariosVehiculos : false });
                }
            });
        });
    }

    handleFormChangeUsuarioSelectObject(name, object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario[name] = object ? { id: object.value } : null;
        this.setState({
            formData: formDataCopy
        });
    }

    handleGerenciadorChange(name, gerenciador) {
        const value = gerenciador;

        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.usuario[name] = value;
        this.setState({
            formData: formDataCopy
        });
    }

    handleDatePickerFormChange(name, event) {
        let date = event ? event.format(): null;
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = date;
        this.setState({
            formData: formDataCopy
        });
    }

    handleDatePickerFormRawChange(name, date) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = date === '' ? null : date;
        this.setState({
            formData: formDataCopy
        });
    }

    handleSubmit(event) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        if(formDataCopy.usuario) {
            formDataCopy.usuario.bases = this.state.basesAsignadas.map(e => {return{id: e}});
            formDataCopy.usuario.centroCostos = this.state.ccAsignados.map(e => {return{id: e}});
            formDataCopy.usuario.email_SSO = this.state.formData.email_SSO;
        }

        this.setState({
            formData: formDataCopy,
            loading: true
        }, () => {
            let component = this;
            //TODO: ver para que saca los responsables y si hay que hacer lo mismo con centro costos
            if (!this.state.formData.esActivo) {
                if (this.esResponsableDeBases) {
                    this.sacarResponsableDeBase();
                }
                if (this.esJefeDeBases) {
                    this.sacarJefeDeBase();
                }
            }
            this.ajaxHandler.fetch('/personas' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
                method: this.props.action === 'ADD' ? 'POST' : 'PUT',
                body: JSON.stringify({
                    ...this.state.formData
                }),
            }).then(response => {
                if(response.status !== 400) {
                    component.exit();
                } else {
                    response.json()
                        .then(data => {
                            this.setState({
                                errors: data.detalle
                            });
                        });
                }
                window.scrollTo(0,0);
            }).catch((error) => {
                this.ajaxHandler.handleError(error);
            }).finally(() => {
                this.setState({ loading: false });
            });
        });

        event.preventDefault();
    }

    handleCancel(event) {
        this.exit();
    }

    exit() {
        this.setState({
            redirectTo: '/personas'
        });
    }

    handleAdjuntosChange(adjuntos) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.adjuntos = adjuntos;
        this.setState({
            formData: formDataCopy
        });
    }

    validateUsuarioSSO() {
        const { email_SSO, email } = this.state.formData;
        const { usuario } = this.state.formData.usuario;
        let error = '';

        // Validar formato de correo electrónico
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email_SSO)) {
            error = 'El formato del correo electrónico no es válido.';
        }

        // Validar que sea distinto del campo email
        if (email_SSO === email) {
            error = 'El correo electrónico SSO debe ser distinto del correo electrónico principal.';
        }

        // Validar que no esté asignado a ningún otro usuario activo        
        if (!this.checkEmailSSOAvailability(email_SSO,usuario)) {
            error = 'El correo electrónico SSO ya está asignado a otro usuario activo.';
        }

        this.setState(prevState => ({
            validationErrors: {
                ...prevState.validationErrors,
                email_SSO: error
            }
        }));
    }

    checkEmailSSOAvailability(email,usuario) {
        return new Promise((resolve, reject) => {
            this.ajaxHandler.fetch('/usuarios/validar-disponibilidad-email-sso', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email_SSO: email , usuario: usuario })
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            }).then(data => {
                if (data.available) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(error => {
                this.ajaxHandler.handleError(error);
                reject(error);
            });
        });
    }

    render() {        
        this.formValidation.validate();
        let state = this.state;
        let formData = state.formData;
        let validationState = this.formValidation.state;

        let sexo = formData.sexo !== '' ? state.sexos.find(e => e.value === formData.sexo) : null;
        let sync = formData.sync !== '' ? state.syncs.find(e => e.value === formData.sync) : null;
        let documentoTipo = formData.documentoTipo ? state.tiposDocumento.find(e => e.value === formData.documentoTipo.id) : null;
        let nacionalidad = formData.nacionalidad ? state.paises.find(e => e.value === formData.nacionalidad.id) : null;
        let categoria = formData.categoria ? state.categorias.find(e => e.value === formData.categoria.id) : null;
        let base = formData.base ? state.basesDisponibles.find(e => e.value === formData.base.id) : null;
        let movil = formData.usuario && formData.usuario.movil ? state.moviles.find(e => e.value === formData.usuario.movil.id) : null;
        let gerenciador = formData.usuario && formData.usuario.gerenciador ? state.gerenciadores.find(e => e.value === formData.usuario.gerenciador.id) : null;
        let pais = formData.pais ? state.paises.find(e => e.value === formData.pais.id) : null;
        let provincia = formData.provincia ? state.provincias.find(e => e.value === formData.provincia.id) : null;
        let localidad = formData.localidad ? state.localidades.find(e => e.value === formData.localidad.id) : null;
        let centroCostos = formData.centroCostos ? state.centroCostos.find(e => e.value === formData.centroCostos.id) : null;
        let companiaOrigen = formData.companiaOrigenId !== '' ? state.companiasOrigen.find(e => e.value === formData.compania_origen_id) : null;
        let requiredSymbol = state.props.action !== 'VIEW' ? ' *' : '';


        let region = formData.region ? state.regiones.find(e => e.value === formData.region.id) : null;
        let subRegion = state.subRegion ? state.subRegiones.find(e => e.value === state.subRegion.id) : null;


        let moviles = formData.moviles && formData.moviles.map(function(el) {
          return {value: el.value, label: el.label};
        });

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="ft-user"></i><FormattedMessage id="personasAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>*<FormattedMessage id="personasAbm.render.general_data.campos_requeridos" defaultMessage=" campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group row photo-upload">
                            <DropzoneComponent config={this.photoUploadConfig} eventHandlers={this.photoUploadHandlers} djsConfig={this.photoUploadDjsConfig} />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            {/* NOMBRE */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="nombre">
                                  <FormattedMessage id="personasAbm.render.general_data.label_nombre" defaultMessage="Nombre"/>{requiredSymbol}:
                                </label>
                                <div className="col-md-8">
                                  {(this.state.disableAvlFields || this.props.action === 'VIEW') ? (
                                  <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                                  ) : (
                                  <div>
                                    <input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_nombre', defaultMessage: '(Requerido)' })} value={formData.nombre ? formData.nombre : ''} onChange={this.handleInputFormChange} />
                                    <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* APELLIDO */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="apellido">
                                  <FormattedMessage id="personasAbm.render.general_data.label_apellido" defaultMessage="Apellido"/>{requiredSymbol}:
                                </label>
                                <div className="col-md-8">
                                  {(this.state.disableAvlFields || this.props.action === 'VIEW') ? (
                                  <div className="form-control-static col-form-label form-value">{formData.apellido}</div>
                                  ) : (
                                  <div>
                                    <input type="text" className="form-control" id="apellido" name="apellido" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_apellido', defaultMessage: '(Requerido)' })} value={formData.apellido ? formData.apellido : ''} onChange={this.handleInputFormChange} />
                                    <div className="help-block text-danger field-message" hidden={validationState.formData.apellido.pristine || validationState.formData.apellido.valid}>{validationState.formData.apellido.message}</div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {/* TIPO DOCUMENTO */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="documentoTipo">
                                  <FormattedMessage id="personasAbm.render.general_data.label_tipo_documento" defaultMessage="Tipo Documento"/>{requiredSymbol}:
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.documentoTipo && formData.documentoTipo.nombre ? formData.documentoTipo.nombre : '' }</div>
                                  ) : (
                                  <div>
                                    <Select
                                      id="documentoTipo"
                                      name="documentoTipo"
                                      placeholder={!state.tiposDocumentoLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_tipo_documento', defaultMessage: 'Seleccione (Requerido)' }) : ''}
                                      options={state.tiposDocumento}
                                      valueKey='value'
                                      labelKey='label'
                                      value={documentoTipo ? { value: documentoTipo.id, label: documentoTipo.label } : null}
                                      onChange={(e) => this.handleFormChangeSelectObject("documentoTipo", e)}
                                      isLoading={state.tiposDocumentoLoading}
                                      disabled={state.tiposDocumentoLoading}
                                    />
                                    <div className="help-block text-danger field-message" hidden={validationState.formData.documentoTipo.pristine || validationState.formData.documentoTipo.valid}>{validationState.formData.documentoTipo.message}</div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* NRO DOCUMENTO */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="documentoNumero">
                                  <FormattedMessage id="personasAbm.render.general_data.label_nro_documento" defaultMessage="Nro. Documento"/>{requiredSymbol}:
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.documentoNumero === 0 ? '': formData.documentoNumero }</div>
                                  ) : (
                                  <div>
                                    <input type="text" className="form-control" id="documentoNumero" name="documentoNumero" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_nro_documento', defaultMessage: '(Requerido)' })} value={formData.documentoNumero === 0 ? '': formData.documentoNumero} onChange={this.handleInputFormChange} />
                                    <div className="help-block text-danger field-message" hidden={validationState.formData.documentoNumero.pristine || validationState.formData.documentoNumero.valid}>{validationState.formData.documentoNumero.message}</div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {/* NACIONALIDAD */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="nacionalidad">
                                  <FormattedMessage id="personasAbm.render.general_data.label_nacionalidad" defaultMessage="Nacionalidad"/>:
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.nacionalidad && formData.nacionalidad.nombre ? formData.nacionalidad.nombre : ''}</div>
                                  ) : (
                                  <div>
                                    <Select
                                      id="nacionalidad"
                                      name="nacionalidad"
                                      placeholder={!state.paisesLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_nacionalidad', defaultMessage: 'Seleccione' }) : ''}
                                      options={state.paises}
                                      valueKey='value'
                                      labelKey='label'
                                      value={nacionalidad ? { value: nacionalidad.id, label: nacionalidad.label } : null}
                                      onChange={(e) => this.handleFormChangeSelectObject("nacionalidad", e)}
                                      isLoading={state.paisesLoading}
                                      disabled={state.paisesLoading}
                                    />
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* SEXO */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="sexo">
                                  <FormattedMessage id="personasAbm.render.general_data.label_sexo" defaultMessage="Sexo"/>:
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{sexo ? sexo.label : ''}</div>
                                  ) : (
                                  <div>
                                    <Select
                                      id="sexo"
                                      name="sexo"
                                      placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_sexo', defaultMessage: 'Seleccione' })}
                                      options={state.sexos}
                                      valueKey='value'
                                      labelKey='label'
                                      value={sexo ? { value: sexo.id, label: sexo.label } : null}
                                      onChange={(e) => this.handleFormChangeSelectString("sexo", e)}
                                    />
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {/* FECHA DE NACIMIENTO */}
                            <div className="col-md-3">
                              <div className="form-group row">
                                <label className="col-md-5 label-control col-form-label" htmlFor="fechaNacimiento">
                                  <FormattedMessage id="personasAbm.render.general_data.label_fecha_de_nacimiento" defaultMessage="Fecha de Nacimiento"/>:
                                </label>
                                <div className="col-md-7">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.fechaNacimiento === null || formData.fechaNacimiento === '0000-00-00' ? '' : moment(formData.fechaNacimiento).format('YYYY-MM-DD') }</div>
                                  ) : (
                                  <div>
                                    <DatePicker
                                      id="fechaNacimiento"
                                      name="fechaNacimiento"
                                      className="form-control date-picker-placeholder"
                                      placeholderText={this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_fecha_de_nacimiento', defaultMessage: 'DD/MM/AAAA' })}
                                      selected={formData.fechaNacimiento === null || formData.fechaNacimiento === '0000-00-00' ? null : moment(formData.fechaNacimiento) }
                                      onChange={(event) => this.handleDatePickerFormChange("fechaNacimiento", event)}
                                      onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaNacimiento", event.target.value)}
                                      maxDate={moment()}
                                      openToDate={moment("1978-01-01")}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                    <div className="help-block text-danger field-message" hidden={validationState.formData.fechaNacimiento.pristine || validationState.formData.fechaNacimiento.valid}>{validationState.formData.fechaNacimiento.message}</div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* ES USUARIO */}
                            <div className="col-md-2">
                              <div className="form-group row">
                                <label className="col-md-6 label-control col-form-label text-nowrap" htmlFor="esUsuario">
                                  <FormattedMessage id="personasAbm.render.general_data.label_es_usuario" defaultMessage="Es Usuario"/>:
                                </label>
                                <div className="col-md-6 mt-auto">
                                  <Switch
                                    onChange={this.handleEsUsuarioChange}
                                    checked={formData.esUsuario ? formData.esUsuario : false}
                                    id="esUsuario"
                                    name="esUsuario"
                                    disabled={(this.state.disableAvlFields || this.props.action === 'VIEW')}
                                    offColor="#FF4961"
                                    onColor="#28D094"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* ESTADO */}
                            <div className="col-md-2">
                              <div className="form-group row">
                                <label className="col-md-6 label-control col-form-label text-nowrap" htmlFor="esActivo">
                                  <FormattedMessage id="personasAbm.render.general_data.label_activo" defaultMessage="Activo"/>:
                                </label>
                                <div className="col-md-6 mt-auto">
                                  <Switch
                                    onChange={this.handleEstadoChange}
                                    checked={formData.esActivo ? formData.esActivo : false}
                                    id="esActivo"
                                    name="esActivo"
                                    disabled={(this.state.disableAvlFields || this.props.action === 'VIEW')}
                                    offColor="#FF4961"
                                    onColor="#28D094"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* ES INTERNO */}
                            <div className="col-md-2">
                                  {(this.state.persona && this.state.persona.is_internal) && (
                                      <div className="form-group row">
                                          <label className="col-md-7 label-control col-form-label text-nowrap" htmlFor="isInternal">
                                              <FormattedMessage id="personasAbm.render.general_data.label_es_interno" defaultMessage="Es Interno"/>:
                                          </label>
                                          <div className="col-md-5 mt-auto">
                                              <Switch
                                                  onChange={this.handleEsInternoChange}
                                                  checked={formData.isInternal ? formData.isInternal : false}
                                                  id="isInternal"
                                                  name="isInternal"
                                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                                  offColor="#FF4961"
                                                  onColor="#28D094"
                                              />
                                          </div>
                                      </div>
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-phone"></i><FormattedMessage id="personasAbm.render.contact_information.header_datos_de_contacto" defaultMessage=" Datos de Contacto"/>
                      </h4>
                      <div className="row">
                        {/* TELEFONO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="telefonoCodArea">
                            <FormattedMessage id="personasAbm.render.contact_information.label_telefono" defaultMessage="Teléfono:"/>
                            </label>
                            <div className="col-md-3">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.telefonoCodArea ? formData.telefonoCodArea : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="telefonoCodArea" name="telefonoCodArea" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.contact_information.placeholder_telefono_telefonoCodArea', defaultMessage: 'Cod. Area' })} value={formData.telefonoCodArea ? formData.telefonoCodArea : ''} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.telefonoCodArea.pristine || validationState.formData.telefonoCodArea.valid}>Solo números</div>
                              </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.telefonoNumero ? formData.telefonoNumero : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="telefonoNumero" name="telefonoNumero" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.contact_information.placeholder_telefono_numero', defaultMessage: 'Número' })} value={formData.telefonoNumero ? formData.telefonoNumero : ''} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.telefonoNumero.pristine || validationState.formData.telefonoNumero.valid}>Solo números</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* CELULAR */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="celularCodArea">
                            <FormattedMessage id="personasAbm.render.contact_information.label_celular" defaultMessage="Celular:"/>
                            </label>
                            <div className="col-md-3">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.celularCodArea ? formData.celularCodArea : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="celularCodArea" name="celularCodArea" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.contact_information.placeholder_celular_celularCodArea', defaultMessage: 'Cod. Area' })} value={formData.celularCodArea ? formData.celularCodArea: ''} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.celularCodArea.pristine || validationState.formData.celularCodArea.valid}>Solo Números</div>
                              </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.celularNumero ? formData.celularNumero : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="celularNumero" name="celularNumero" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.contact_information.placeholder_celular_celularNumero', defaultMessage: 'Número' })} value={formData.celularNumero ? formData.celularNumero : ''} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.celularNumero.pristine || validationState.formData.celularNumero.valid}>Solo Números</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* EMAIL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="email">
                              <FormattedMessage id="personasAbm.render.contact_information.label_email" defaultMessage="Email"/>{formData.esUsuario ? requiredSymbol : ''}:
                            </label>
                            <div className="col-md-9">
                              {(this.state.disableAvlFields || this.props.action === 'VIEW')  ? (
                              <div className="form-control-static col-form-label form-value">{formData.email}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="email" name="email" placeholder={formData.esUsuario ? this.props.intl.formatMessage({ id: 'personasAbm.render.contact_information.placeholder_email', defaultMessage: '(Requerido)' }) : ''} value={formData.email ? formData.email : ''} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.email.pristine || validationState.formData.email.valid}>{validationState.formData.email.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>


                        {/* SYNC */}
                        { (Security.hasPermission('USUARIO_EDITAR_SYNC')) &&
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-6 label-control col-form-label text-nowrap" htmlFor="usarMailPersonal">
                                <FormattedMessage id="personasAbm.render.contact_information.label_usar_mail_personal" defaultMessage="Usar mail personal"/>:
                              </label>
                              <div className="col-md-6">
                                  {this.props.action === 'VIEW' ? (
                                    <div className="form-control-static col-form-label form-value">{sync ? sync.label : ''}</div>
                                  ) : (
                                    <div>
                                        <Select
                                          id="sync"
                                          name="sync"
                                          placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.general_data.placeholder_usar_mail_personal', defaultMessage: 'Seleccione' })}
                                          options={state.syncs}
                                          valueKey='value'
                                          labelKey='label'
                                          value={sync ? { value: sync.id, label: sync.label } : null}
                                          onChange={(e) => this.handleFormChangeSelectString("sync", e)}
                                        />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="row">
                        {/* USUARIO SSO */}
                        {this.state.formData.esUsuario && this.state.ssoHabilitado && (
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="email_SSO">
                                  <FormattedMessage id="personasAbm.render.user_profile.label_Usuario_SSO" defaultMessage="Usuario SSO" />:
                                </label>
                                <div className="col-md-9">
                                  {(this.props.action === 'VIEW') || !Security.hasPermission('PERSONAS_MODIFICAR') ? (
                                    <div className="form-control-static col-form-label form-value">{this.state.formData.email_SSO || ''}</div>
                                  ) : (
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="email_SSO"
                                        name="email_SSO"
                                        value={this.state.formData.email_SSO || ''}
                                        onChange={this.handleInputFormChange}
                                      />
                                      <div className="help-block text-danger field-message" hidden={validationState.formData.email_SSO.pristine || validationState.formData.email_SSO.valid}>{validationState.formData.email_SSO.message}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body" id="container-direccion">
                      <h4 className="form-section mt-2">
                        <i className="ft-map-pin"></i><FormattedMessage id="personasAbm.render.location_data.header_datos_de_ubicacion" defaultMessage=" Datos de Ubicación"/>
                      </h4>
                      <DireccionAutocompletar
                      action={this.props.action}
                      camposHidden={["codigoPostal"]}
                      cambioDireccion={this.handleChangeDireccion}
                      formulario={this.state.direccionForm}
                      camposObligatorios={[]}
                      crearProvincias={true}
                      crearLocalidades={true}
                      />
                      {/* OBSERVACIONES */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="observaciones">
                              <FormattedMessage id="personasAbm.render.location_data.label_piso" defaultMessage="Piso:"/>
                            </label>
                            <div className="col-md-3">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.piso}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="piso" name="piso" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.location_data.placeholder_piso', defaultMessage: 'Piso' })} value={formData.piso ? formData.piso : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="observaciones">
                              <FormattedMessage id="personasAbm.render.location_data.label_departamento" defaultMessage="Departamento:"/>
                            </label>
                            <div className="col-md-3">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.departamento}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="departamento" name="departamento" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.location_data.placeholder_departamento', defaultMessage: 'Departamento' })} value={formData.departamento ? formData.departamento : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="observaciones">
                            <FormattedMessage id="personasAbm.render.location_data.label_observaciones" defaultMessage="Observaciones:"/>
                          </label>
                          <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                            <div className="form-control-static col-form-label form-value">{formData.observaciones}</div>
                            ) : (
                            <div>
                               <textarea className="form-control" id="observaciones" name="observaciones" rows="3" placeholder="" value={formData.observaciones ? formData.observaciones : ''} onChange={this.handleInputFormChange}></textarea>
                            </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-briefcase"></i><FormattedMessage id="personasAbm.render.labor_data.header_datos_laborales" defaultMessage=" Datos Laborales"/>
                      </h4>
                      <div className="row">
                        {/* LEGAJO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="legajoNumero">
                              <FormattedMessage id="personasAbm.render.labor_data.label_nro_legajo" defaultMessage="Nro. Legajo"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.legajoNumero ? formData.legajoNumero : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="legajoNumero" name="legajoNumero" placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_nro_legajo', defaultMessage: '(Requerido)' })} value={formData.legajoNumero} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.legajoNumero.pristine || validationState.formData.legajoNumero.valid}>{validationState.formData.legajoNumero.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* CATEGORÍA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="categoria">
                              <FormattedMessage id="personasAbm.render.labor_data.label_categoria" defaultMessage="Categoría:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.categoria && formData.categoria.nombre ? formData.categoria.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="categoria"
                                  name="categoria"
                                  placeholder={!state.categoriasLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_categoria', defaultMessage: 'Seleccione' }) : ''}
                                  options={state.categorias}
                                  valueKey='value'
                                  labelKey='label'
                                  value={categoria ? { value: categoria.id, label: categoria.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("categoria", e)}
                                  isLoading={state.categoriasLoading}
                                  disabled={state.categoriasLoading}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* FECHA DE INGRESO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="fechaIngreso">
                              <FormattedMessage id="personasAbm.render.labor_data.label_fecha_de_ingreso" defaultMessage="Fecha de Ingreso:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaIngreso === null || formData.fechaIngreso === '0000-00-00' ? '' : moment(formData.fechaIngreso).format('DD/MM/YYYY') }</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaIngreso"
                                  name="fechaIngreso"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_fecha_de_ingreso', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaIngreso === null || formData.fechaIngreso === '0000-00-00' ? null : moment(formData.fechaIngreso) }
                                  onChange={(event) => this.handleDatePickerFormChange("fechaIngreso", event)}
                                  onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaIngreso", event.target.value)}
                                  maxDate={moment()}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.fechaIngreso.pristine || validationState.formData.fechaIngreso.valid}>{validationState.formData.fechaIngreso.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* FECHA DE BAJA */}
                        <div className="col-md-6">
                          {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="fechaBaja">
                              <FormattedMessage id="personasAbm.render.labor_data.label_fecha_baja" defaultMessage="Fecha de Baja:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaBaja === null || formData.fechaBaja === '0000-00-00' ? '' : moment(formData.fechaBaja).format('DD/MM/YYYY') }</div>
                              ) : (
                                this.props.action === 'EDIT' ? (
                                <div>
                                  <DatePicker
                                    id="fechaBaja"
                                    name="fechaBaja"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_fecha_baja', defaultMessage: 'DD/MM/AAAA' })}
                                    selected={formData.fechaBaja === null || formData.fechaBaja === '0000-00-00' ? null : moment(formData.fechaBaja) }
                                    onChange={(event) => this.handleDatePickerFormChange("fechaBaja", event)}
                                    onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaBaja", event.target.value)}
                                    maxDate={moment()}
                                    minDate={moment(formData.fechaIngreso)}
                                  />
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.fechaBaja.pristine || validationState.formData.fechaBaja.valid}>{validationState.formData.fechaBaja.message}</div>
                                </div>
                                ) : (
                                <div></div>
                                )
                              )}
                            </div>
                          </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        {/* BASE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="base">
                              <FormattedMessage id="personasAbm.render.labor_data.label_base" defaultMessage="Base"/>{this.state.baseMandatory ? requiredSymbol : ''}:
                            </label>
                            <div className="col-md-9">
                              {(this.state.disableAvlFields || this.props.action === 'VIEW')  ? (
                              <div className="form-control-static col-form-label form-value">{formData.base && formData.base.descripcion ? formData.base.descripcion : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="base"
                                  name="base"
                                  placeholder={!state.basesDisponiblesLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_base', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.basesDisponibles}
                                  valueKey='value'
                                  labelKey='label'
                                  value={base ? { value: base.id, label: base.label } : null}
                                  onChange={(e) => this.handleBaseChange(e)}
                                  isLoading={state.basesDisponiblesLoading}
                                  disabled={state.basesDisponiblesLoading}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* CENTRO COSTO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="centroCostos">
                              <FormattedMessage id="personasAbm.render.labor_data.label_centro_de_costo" defaultMessage="Centro de Costo"/>{this.state.cecoMandatory ? requiredSymbol : ''}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || !state.cecoEnabled ? (
                              <div className="form-control-static col-form-label form-value">{formData.centroCostos ? formData.centroCostos.nombre + ' - '+ formData.centroCostos.observaciones : '' }</div>
                              ) : (
                              <div>
                                <Select
                                  id="centroCostos"
                                  name="centroCostos"
                                  placeholder={!state.centroCostosLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_centro_de_costo', defaultMessage: 'Seleccione (Requerido)' }) : ''}
                                  options={state.centroCostos}
                                  valueKey='value'
                                  labelKey='label'
                                  value={centroCostos ? { value: centroCostos.id, label: centroCostos.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("centroCostos", e)}
                                  isLoading={state.centroCostosLoading}
                                  disabled={state.centroCostosLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.centroCostos.pristine || validationState.formData.centroCostos.valid}>{validationState.formData.centroCostos.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* CONTRATO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="contrato">
                              <FormattedMessage id="personasAbm.render.labor_data.label_contrato" defaultMessage="Contrato:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.contrato ? formData.contrato : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="contrato" name="contrato" placeholder="" value={formData.contrato ? formData.contrato : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* LLAVE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="llaves">
                              <FormattedMessage id="personasAbm.render.labor_data.label_llaves" defaultMessage="Llaves:"/>
                            </label>
                            <div className="col-md-9">

                              <div>
                                <Select
                                    id="llaves"
                                    name="llaves"
                                    placeholder={!state.llavesOptionsLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_llave', defaultMessage: 'Seleccione' }) : ''}
                                    options={this.state.llavesOptions}
                                    valueKey='value'
                                    labelKey='label'
                                    multi={true}
                                    removeSelected={true}
                                    value={this.state.formData.llaves}
                                    onChange={(e) => this.handleFormChangeSelectString("llaves", e)}
                                    isLoading={state.llavesOptionsLoading}
                                    disabled={this.props.action === 'VIEW' || state.llavesOptionsLoading}
                                />
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        {/* YPF RUTA */}
                        {this.state.ypfRutaView ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="ypfRuta">
                              <FormattedMessage id="personasAbm.render.labor_data.label_clave_Pin_YPF" defaultMessage="Clave Pin YPF:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.ypfRuta ? formData.ypfRuta : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="ypfRuta" name="ypfRuta" placeholder="" value={formData.ypfRuta ? formData.ypfRuta : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                        {/* COMENTARIOS LABORALES */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="comentariosLaborales">
                              <FormattedMessage id="personasAbm.render.labor_data.label_comentarios_laborales" defaultMessage="Comentarios Laborales:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.comentariosLaborales ? formData.comentariosLaborales : ''}</div>
                              ) : (
                              <div>
                                <textarea className="form-control" id="comentariosLaborales" name="comentariosLaborales" rows="3" placeholder="" value={formData.comentariosLaborales ? formData.comentariosLaborales : ''} onChange={this.handleInputFormChange}></textarea>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* COMPAÑÍA ORIGEN */}
                        {this.state.companiaOrigenEnabled ? (
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="companiaOrigen">
                                  <FormattedMessage id="personasAbm.render.labor_data.label_cia_origen" defaultMessage="Cía. Origen:"/>
                                </label>
                                <div className="col-md-9">
                                  {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                                      <div className="form-control-static col-form-label form-value">{formData.companiaOrigenEloquent ? formData.companiaOrigenEloquent.nombre : ''}</div>
                                  ) : (
                                      <div>
                                        <Select
                                            id="companiaOrigen"
                                            name="companiaOrigen"
                                            placeholder={!state.companiasLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.labor_data.placeholder_cia_origen', defaultMessage: 'Seleccione' }) : ''}
                                            options={this.state.companiasOrigen}
                                            valueKey='value'
                                            labelKey='label'
                                            value={companiaOrigen ? { value: companiaOrigen.value, label: companiaOrigen.label } : null}
                                            onChange={(e) => this.handleFormChangeSelectString("companiaOrigen", e)}
                                            isLoading={state.companiasLoading}
                                            disabled={state.companiasLoading}
                                        />
                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card pull-up" style={{display: formData.esUsuario ? '' : 'none' }}>
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-shield"></i><FormattedMessage id="personasAbm.render.user_profile.header_perfil_de_usuario" defaultMessage=" Perfil de Usuario"/>
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          {/* RECIBE NOTIFICACIONES POR MAIL */}
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="notificacionesActivas">
                              <FormattedMessage id="personasAbm.render.user_profile.label_notificaciones_por_mail" defaultMessage="Notificaciones por mail:"/>
                            </label>
                            <div className="col-md-8 mt-auto">
                              <Switch
                                onChange={this.handleNotificacionesActivasChange}
                                checked={formData.usuario && formData.usuario.notificacionesActivas ? formData.usuario.notificacionesActivas : false}
                                id="notificacionesActivas"
                                name="notificacionesActivas"
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                        {ConfigBusiness.get('2fa.habilitado') === 'true' ? (
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="esActivo">
                                <FormattedMessage id="personasAbm.render.general_data.label_habilitar_twofa" defaultMessage="Habilitar 2FA"/>:
                              </label>
                              <div className="col-md-8 mt-auto">
                                <Switch
                                  onChange={this.handleEnableTwoFAChange}
                                  checked={formData.usuario && formData.usuario.enable_twofa ? formData.usuario.enable_twofa : false}
                                  id="enable_twofa"
                                  name="enable_twofa"
                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                />
                              </div>
                            </div>
                          </div>
                        ) : '' }
                      </div>
                      <div style={{display: formData.esUsuario ? '' : 'none' }}>
                        <div className="row">
                          {/* USUARIO */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="usuario">
                                <FormattedMessage id="personasAbm.render.user_profile.label_user" defaultMessage="Usuario"/>{requiredSymbol}:
                              </label>
                              <div className="col-md-8">
                                {(this.state.disableAvlFields || this.props.action === 'VIEW')  ? (
                                <div className="form-control-static col-form-label form-value">{formData.usuario && formData.usuario.usuario ? formData.usuario.usuario : ''}</div>
                                ) : (
                                <div>
                                  <input 
                                  type="text" 
                                  className="form-control" 
                                  id="usuario" 
                                  name="usuario" 
                                  placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.user_profile.placeholder_notificaciones_por_mail', defaultMessage: '(Requerido)' })} 
                                  value={formData.usuario ? formData.usuario.usuario : ''} 
                                  onChange={this.handleUsuarioFormChange} />
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.usuario.usuario.pristine || validationState.formData.usuario.usuario.valid}>{validationState.formData.usuario.usuario.message}</div>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* PERFIL */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="perfil">
                                <FormattedMessage id="personasAbm.render.user_profile.label_perfil" defaultMessage="Perfil"/>{formData.esUsuario ? requiredSymbol : ''}:
                              </label>
                              <div className="col-md-8">
                                {(this.state.disableAvlFields || this.props.action === 'VIEW')  ? (
                                <div className="form-control-static col-form-label form-value">{formData.usuario ? (formData.usuario.perfil ? formData.usuario.perfil.nombre: '') : ''}</div>
                                ) : (
                                <div>
                                  <Select
                                    id="perfil"
                                    name="perfil"
                                    placeholder={!state.perfilesLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.user_profile.placeholder_perfil', defaultMessage: 'Seleccione' }) : ''}
                                    options={this.state.perfiles}
                                    valueKey='id'
                                    labelKey='nombre'
                                    value={formData.usuario ? formData.usuario.perfil : null }
                                    onChange={(e) => this.handlePerfilChange("perfil", e)}
                                    isLoading={state.perfilesLoading}
                                    disabled={state.perfilesLoading}
                                  />
                                  {<div className="help-block text-danger field-message" hidden={validationState.formData.usuario.perfil.pristine || validationState.formData.usuario.perfil.valid}>{validationState.formData.usuario.perfil.message}</div>
                                  }
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                           {/* MOVILES */}
                          <div className="col-md-6">
                            <div style={{display: this.state.hasPermisoMovil ? '' : 'none' }}>
                              <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="movil">
                                  <FormattedMessage id="personasAbm.render.user_profile.label_movil" defaultMessage="Móvil:"/>
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.usuario ? (formData.usuario.movil ? formData.usuario.movil.dominio : '') : ''}</div>
                                  ) : (
                                  <div>
                                    <Select
                                      id="movil"
                                      name="movil"
                                      placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.user_profile.placeholder_movil', defaultMessage: 'Seleccione' })}
                                      options={state.moviles}
                                      valueKey='value'
                                      labelKey='label'
                                      value={formData.usuario && movil ? { value: movil.id, label: movil.label } : null}
                                      onChange={(e) => this.handleFormChangeUsuarioSelectObject("movil", e)}
                                    />
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* GERENCIADORES */}
                          <div className="col-md-6">
                            <div style={{display: (this.state.hasPermisoGerenciador || this.state.hasPermisoEscribano || this.state.hasPerfilGestor) ? '' : 'none' }}>
                              <div className="form-group row">
                              {ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'false' ?(
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="gerenciador">
                                  <FormattedMessage id="personasAbm.render.user_profile.label_gerenciador" defaultMessage="Gerenciador:"/>
                                </label>
                              ):(
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="gerenciador">
                                  <FormattedMessage id="personasAbm.render.user_profile.label_proveedor" defaultMessage="Proveedor:"/>
                                </label>
                              )}
                                <div className="col-md-8">
                                  {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label form-value">{formData.usuario ? (formData.usuario.gerenciador ? formData.usuario.gerenciador.razonSocial : '') : ''}</div>
                                  ) : (
                                  <div>
                                    <Select
                                      id="gerenciador"
                                      name="gerenciador"
                                      placeholder={this.props.intl.formatMessage({ id: 'personasAbm.render.user_profile.placeholder_gerenciador', defaultMessage: 'Seleccione' })}
                                      options={state.gerenciadores}
                                      valueKey='value'
                                      labelKey='label'
                                      value={formData.usuario && gerenciador ? { value: gerenciador.id, label: gerenciador.label } : null}
                                      onChange={(e) => this.handleFormChangeUsuarioSelectObject("gerenciador", e)}
                                    />
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{display: this.state.hasPermisoVariosVehiculos ? '' : 'none' }}>
                          <div className="col-md-12">
                            <div className="form-group row">
                                <label className="col-md-2 label-control col-form-label" htmlFor="Móviles">
                                    <FormattedMessage id="usuariosMovilesAbm.render.labor_data.label_usuarios_moviles" defaultMessage="Móviles:"/>
                                </label>
                                <div className="col-md-10">
                                      <Select
                                          id="moviles"
                                          name="moviles"
                                          placeholder={!state.movilesOptionsLoading ? this.props.intl.formatMessage({ id: 'usuariosMovilesAbm.render.labor_data.placeholder_usuarios_moviles', defaultMessage: 'Seleccione' }) : ''}
                                          options={this.state.movilesOptions}
                                          valueKey='value'
                                          labelKey='label'
                                          multi={true}
                                          value={moviles}
                                          onChange={(e) => this.handleFormChangeSelectString("movilesUsuarios",e)}
                                          isLoading={state.movilesOptionsLoading}
                                          disabled={this.props.action === 'VIEW' || state.movilesOptionsLoading}
                                      />
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div style={{display: (this.state.hasPermisoBases && !this.state.hasPermisoTrabajarTodasBases) ? '' : 'none' }}>
                              {/* BASES */}
                              {(this.state.disableAvlFields || this.props.action === 'VIEW')  ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    {this.state.basesAsignadas && this.state.basesAsignadas.length ? (
                                    <div className="form-group row">
                                      <div className="col-md-4"></div>
                                      <div className="col-md-8">
                                        <table className="table table-bordered">
                                          <thead className="thead-fleet bases">
                                            <tr>
                                              <th scope="col"><FormattedMessage id="personasAbm.render.user_profile.label_bases_asignadas" defaultMessage="Bases Asignadas"/></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.basesAsignadas.map((base, index)=>{
                                            return(
                                              <tr key={index}>
                                                <td className="align-middle">{base}</td>
                                              </tr>
                                            );
                                          })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    ) : (<p><FormattedMessage id="personasAbm.render.user_profile.label_esta_persona_no_cuenta_con_bases_asignadas" defaultMessage="Esta persona no cuenta con bases asignadas."/></p>)}
                                  </div>
                                </div>
                              ) : (
                              <div className="form-group">
                                <div className="row">
                                  {/* REGION */}
                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="region">
                                        <FormattedMessage id="personasAbm.render.user_profile.label_region" defaultMessage="Región:"/>
                                      </label>
                                      <div className="col-md-8">
                                        {this.props.action === 'VIEW' ? (
                                        <div className="form-control-static col-form-label form-value">{formData.nacionalidad && formData.nacionalidad.nombre ? formData.nacionalidad.nombre : ''}</div>
                                        ) : (
                                        <div>
                                          <Select
                                            id="region"
                                            name="region"
                                            placeholder={!state.regionesLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.user_profile.placeholder_region', defaultMessage: 'Seleccione' }) : ''}
                                            options={state.regiones}
                                            valueKey='value'
                                            labelKey='label'
                                            value={region ? { value: region.id, label: region.label } : null}
                                            onChange={(e) => this.handleRegionChange(e)}
                                            isLoading={state.regionesLoading}
                                            disabled={state.regionesLoading}
                                          />
                                        </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                              {/* SUBREGION */}
                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="region">
                                        <FormattedMessage id="personasAbm.render.user_profile.label_subregion" defaultMessage="Sub Region:"/>
                                      </label>
                                      <div className="col-md-8">
                                        {this.props.action === 'VIEW' ? (
                                        <div className="form-control-static col-form-label form-value">{formData.nacionalidad && formData.nacionalidad.nombre ? formData.nacionalidad.nombre : ''}</div>
                                        ) : (
                                        <div>
                                          <Select
                                            id="subRegion"
                                            name="subRegion"
                                            placeholder={!state.subRegionesLoading ? this.props.intl.formatMessage({ id: 'personasAbm.render.user_profile.placeholder_subregion', defaultMessage: 'Seleccione' }) : ''}
                                            options={state.subRegiones}
                                            valueKey='id'
                                            labelKey='nombre'
                                            value={this.state.subRegion}
                                            onChange={(e) => this.handleSubRegionChange(e)}
                                            isLoading={state.subRegionesLoading}
                                            disabled={(state.subRegionesLoading || (!region))}
                                          />
                                        </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <select id="dualListBases" multiple="multiple" size="10" ref="duallistbox" value={this.state.basesAsignadas} onChange={this.handleBasesChange}>
                                { state.basesDisponiblesDualList.map(function(base) {
                                  return <option className="pl-1" key={base.value} value={base.value}>{base.label}</option>;
                                })}
                                </select>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div style={{display: this.state.hasPermisoCC ? '' : 'none' }}>
                              {/* CENTROS DE COSTO */}
                              {this.props.action === 'VIEW' ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    {this.state.ccAsignados.length > 0 ? (
                                    <div className="form-group row">
                                      <div className="col-md-4"></div>
                                      <div className="col-md-8">
                                        <table className="table table-bordered">
                                          <thead className="thead-fleet">
                                            <tr>
                                              <th scope="col">Centros de Costos Asignados</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.ccAsignados.map((cc, index)=>{
                                            return(
                                              <tr key={index}>
                                                <td className="align-middle">{cc}</td>
                                              </tr>
                                            );
                                          })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    ) : (<p>Esta persona no cuenta con centros de costos asignados.</p>)}
                                  </div>
                                </div>
                              ) : (
                              <div className="form-group">
                                <select id="dualListCC" multiple="multiple" size="10" ref="duallistboxCC" value={this.state.ccAsignados} onChange={this.handleCCChange}>
                                { state.ccDisponiblesDualList.map(function(cc) {
                                  return <option className="pl-1" key={cc.value} value={cc.value}>{cc.label}</option>;
                                })}
                                </select>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {(this.props.action === 'ADD' || (this.props.action !== 'ADD' && this.state.formData.id)) && (
                <PersonasAbmAdjuntos persona={this.state.formData} action={this.props.action} callbackUpdate={this.handleAdjuntosChange.bind(this)}></PersonasAbmAdjuntos>
                )}

                {!this.props.hideBack && (
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
                          <i className="fa fa-check-circle"></i><FormattedMessage id="personasAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'personasAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'personasAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(PersonasAbm);
